import { EventData } from '../../components/Enrolements/types';

export const SET_ASSIGN_EVENT_LIST = 'SET_ASSIGN_EVENT_LIST';

export interface IAssignEventListPayload {
    type: typeof SET_ASSIGN_EVENT_LIST;
    payload: {
        alreadyEnrolments?: string[];
        eventsData: EventData;
    };
}
