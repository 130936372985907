import React from 'react';
import Navbar from '../components/Navbar/TopNav/Nav';
import SideMenu from '../components/Navbar/SideMenu/SideMenu';
import HomeTitle from '../components/HomeTitle/HomeTitle';

const PageNotExist = () => {
    return (
        <div>
            <Navbar />
            <SideMenu />
            <HomeTitle
                title="Nic tu nie ma!"
                subtitle={`Strona, którą próbujesz wyświetlić nie istnieje lub została usunięta.`}
            />
        </div>
    );
};
export default PageNotExist;
