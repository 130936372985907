import produce from 'immer';
import { LOGIN_USER, LOGOUT_USER } from './types.auth';

export interface IAuthInitState {
    login: {
        readonly email: string;
        readonly userId: string;
        readonly club: string;
        readonly isAdmin: boolean;
        readonly isLogin: boolean;
    };
}

interface IReducer extends IAuthInitState {
    type: string;
    payload: PayloadType;
}

const authInitState: IAuthInitState = {
    login: {
        email: '',
        userId: '',
        isAdmin: false,
        isLogin: false,
        club: '',
    },
};
type PayloadType = Pick<IAuthInitState['login'], 'email' | 'userId' | 'isAdmin' | 'club'>;
export const authReducer = <T extends IReducer>(state: IAuthInitState = authInitState, action: T): IAuthInitState => {
    switch (action.type) {
        case LOGIN_USER:
            const { email, userId, isAdmin, club } = action.payload;
            return produce<IAuthInitState>(state, draftState => {
                draftState.login.email = email;
                draftState.login.userId = userId;
                draftState.login.isLogin = true;
                draftState.login.isAdmin = isAdmin;
                draftState.login.club = club;
            });
        case LOGOUT_USER:
            return produce<IAuthInitState>(state, draftState => {
                draftState.login.email = '';
                draftState.login.userId = '';
                draftState.login.club = '';
                draftState.login.isLogin = false;
                draftState.login.isAdmin = false;
            });
        default:
            break;
    }
    return state;
};
export default authReducer;
