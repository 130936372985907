import { makeStyles } from '@material-ui/styles';

export const useStyle = makeStyles(() => ({
    root: {
        width: '100%',
        overflow: 'hidden',
        margin: '0',
    },
    title: {
        '@media screen and (max-width: 830px)': {
            display: 'none',
        },
    },
    hamburger: {
        fontSize: '20px',
        color: '#fff',
        '@media screen and (min-width: 1264px)': {
            display: 'none',
        },
    },
    standardMenu: {
        display: 'flex',
        '@media screen and (max-width: 1264px)': {
            display: 'none',
        },
    },
    menuItem: {
        color: 'white',
        border: '1px solid #fff',
        marginLeft: '10px',
    },
    linkNavItem: {
        textDecoration: 'none',
        color: '#fff',
    },
}));
