import React, { memo } from 'react';
import { TextField } from '@material-ui/core';
import { useStyle } from '../RegisterForm.style';
import { useFormikContext } from 'formik';
import { IRegisterFormContext } from '../types';

const PersonalData = () => {
    const classes = useStyle();
    const { errors, values, handleChange, handleBlur }: IRegisterFormContext = useFormikContext();
    return (
        <div className={classes.formWrapper}>
            <TextField
                error={!!errors?.firstName ? true : false}
                className={classes.formItems}
                onChange={handleChange}
                onBlur={handleBlur}
                name="firstName"
                label="Imie"
                value={values.firstName}
                helperText={errors?.firstName}
                variant="filled"
            />
            <TextField
                error={!!errors?.lastName ? true : false}
                className={classes.formItems}
                onChange={handleChange}
                onBlur={handleBlur}
                name="lastName"
                label="Nazwisko"
                value={values.lastName}
                helperText={errors?.lastName}
                variant="filled"
            />
            <TextField
                error={!!errors?.telNumber ? true : false}
                className={classes.formItems}
                onChange={handleChange}
                onBlur={handleBlur}
                name="telNumber"
                label="Nr. Telefonu"
                value={values.telNumber}
                helperText={errors?.telNumber}
                variant="filled"
            />
            <TextField
                error={!!errors?.city ? true : false}
                className={classes.formItems}
                onChange={handleChange}
                onBlur={handleBlur}
                name="city"
                label="Miasto"
                value={values.city}
                helperText={errors?.city}
                variant="filled"
            />
        </div>
    );
};

export default memo(PersonalData);
