import React, { lazy } from 'react';
import { Route, Switch } from 'react-router';
import useGaTracker from './hooks/useGaTracker';
import Login from './pages/Login';
import PageNotExists from './pages/PageNotExists';
import Register from './pages/Register';

const MarkPay = lazy(() => import('./pages/Admin/MarkPay'));
const Admin = lazy(() => import('./pages/Admin/Admin'));
const AssignOtherPerson = lazy(() => import('./pages/AssignOtherPerson'));
const Profile = lazy(() => import('./pages/Profile'));
const PasswordPageReset = lazy(() => import('./pages/PasswordPageReset'));
const Enrolments = lazy(() => import('./pages/Enrolments'));
const PlayerList = lazy(() => import('./pages/PlayersList'));

const Routing = () => {
    useGaTracker()
    return (
        <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/register" component={Register} />
            <Route path="/profile" component={Profile} />
            <Route path="/apply" component={Enrolments} />
            <Route path="/admin" component={Admin} />
            <Route path="/markPay/:eventId?" component={MarkPay} />
            <Route path="/resetPass/:token?" component={PasswordPageReset} />
            <Route path="/playerlist/:eventId?" component={PlayerList} />
            <Route path="/assignOtherPerson/:eventId" component={AssignOtherPerson} />
            <Route component={PageNotExists} />
        </Switch>
    );
};

export default Routing;
