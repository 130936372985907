import React from 'react';
import bg from '../../static/bg.svg';
import { makeStyles } from '@material-ui/styles';
const useStyle = makeStyles({
    background: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        opacity: 0.09,
        zIndex: -5,
        pointerEvents: 'none'
    },
});
const Background = () => {
    const classes = useStyle();
    return <img src={bg} className={classes.background} alt="background" />;
}
export default Background; 