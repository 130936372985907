import { makeStyles } from '@material-ui/styles';

export const useStyle = makeStyles(() => ({
           root: {
               height: 'calc(100vh - 79px)',
               width: '100vw',
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
           },
           formWrapper: {
               display: 'flex',
               flexWrap: 'wrap',
               justifyContent: 'center',
               width: '100%',
           },
           rodoCredential: {
               display: 'flex',
               alignItems: 'flex-start',
           },
           form: {
               display: 'flex',
               justifyContent: 'center',
               flexDirection: 'column',
               height: '100%',
               '@media screen and (min-width: 414px)': {
                   width: '80vw',
               },
               '@media screen and (min-width: 768px)': {
                   width: '60vw',
               },
               '@media screen and (min-width: 1200px)': {
                   width: '25vw',
               },
           },
           buttonWrapper: {
               display: 'flex',
               justifyContent: 'center',
           },
           buttonItem: {
               margin: '0px 5px 0px 5px',
           },
           formItems: {
               width: '90%',
               margin: '10px',
           },
           rodoWrapper: {
               padding: '5px',
               height: '50vh',
               width: '100%',
               overflowY: 'scroll',
           },
           errorLabel: {
               color: '#ed6258',
           },
           captchaWrapper: {
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
               width: '100%'
           }
       }));
