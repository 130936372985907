import { Checkbox, FormControl, FormControlLabel, FormGroup, Typography } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import React, { memo } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useStyle } from '../RegisterForm.style';
import { IRegisterFormContext } from '../types';
const RequiredCredentials = () => {
    const { values, handleChange }: IRegisterFormContext = useFormikContext();
    const [_field, _meta, helpers] = useField<string>("captcha");
    const classes = useStyle();
    return (
        <div className={classes.formWrapper}>
            <div className={classes.rodoWrapper}>
                <div className={classes.rodoCredential}>
                    <Checkbox
                        color="primary"
                        value={values.rodoCredential}
                        checked={values.rodoCredential}
                        name="rodoCredential"
                        onChange={handleChange}
                    />
                    <Typography variant="body2" component={'span'}>
                        Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z Rozporządzeniem Parlamentu
                        Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych
                        w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz
                        uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych), publ. Dz. Urz. UE L Nr
                        119, s. 1 w celach związanych z organizacją biegu.
                        <a
                            href="https://eur-lex.europa.eu/legal-content/PL/TXT/?uri=celex%3A32016R0679"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Pełen tekst rozporządzenia
                        </a>
                        <br></br>
                        <a
                            href="https://www.gov.pl/documents/31305/436699/RODO.pdf/9b7e519b-0d5c-1ef8-4caf-02f8d247aa1d"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Informator Ministerstwa Cyfryzacji
                        </a>
                        <br></br>
                        KLAUZULA INFORMACYJNA
                        <ol>
                            <li>
                                Administratorem Pani/Pana danych osobowych jest Stowarzyszenie "Tuczno moje małe Kujawy"
                            </li>
                            <li>
                                W sprawach z zakresu ochrony danych osobowych mogą Państwo kontaktować się z Inspektorem
                                Ochrony Danych pod adresem e-mail: <a href="mailto:kontakt@biegmuzealny.pl">kontakt@biegmuzealny.pl</a>
                            </li>
                            <li>
                                W sprawach z zakresu ochrony danych osobowych mogą Państwo kontaktować się z Inspektorem
                                Ochrony Danych pod adresem e-mail: <a href="mailto:kontakt@biegmuzealny.pl">kontakt@biegmuzealnypl</a>
                            </li>
                            <li>Dane osobowe będą przetwarzane w celach związanych z organizacją biegu</li>
                            <li>
                                Dane osobowe będą przetwarzane do czasu cofnięcia zgody na przetwarzanie danych
                                osobowych.
                            </li>

                            <li>
                                Podstawą prawną przetwarzania danych jest art. 6 ust. 1 lit. a) ww. Rozporządzenia.{' '}
                            </li>
                            <li>
                                Odbiorcami Pani/Pana danych będą podmioty, które na podstawie zawartych umów
                                przetwarzają dane osobowe w imieniu Administratora.
                            </li>
                            <li>
                                Osoba, której dane dotyczą ma prawo do:
                                <ul>
                                    <li>
                                        {' '}
                                        żądania dostępu do danych osobowych oraz ich sprostowania, usunięcia lub
                                        ograniczenia przetwarzania danych osobowych. Edycja danych możliwa jest w każdej
                                        chwili za pomocą formularza do którego dostęp można uzyskać poprzez link zawarty
                                        w mailu wysyłany do każdego uczestnika biegu.
                                    </li>
                                    <li>
                                        cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem
                                        przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.
                                    </li>
                                    <li>
                                        wniesienia skargi do organu nadzorczego w przypadku gdy przetwarzanie danych
                                        odbywa się z naruszeniem przepisów powyższego rozporządzenia tj. Prezesa Ochrony
                                        Danych Osobowych, ul. Stawki 2, 00-193 Warszawa.
                                    </li>
                                </ul>
                            </li>
                        </ol>
                    </Typography>
                </div>

                <Typography variant="body2">
                    Podanie danych osobowych jest dobrowolne, przy czym konsekwencją niepodania danych osobowych jest
                    brak możliwości udziału w biegu. Ponadto informujemy, iż w związku z przetwarzaniem Pani/Pana danych
                    osobowych nie podlega Pan/Pani decyzjom, które się opierają wyłącznie na zautomatyzowanym
                    przetwarzaniu, w tym profilowaniu, o czym stanowi art. 22 ogólnego rozporządzenia o ochronie danych
                    osobowych.
                </Typography>
                <br></br>
                <FormControl component="fieldset" className={classes.formItems}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    value={values.medicalCredential}
                                    checked={values.medicalCredential}
                                    name="medicalCredential"
                                    onChange={handleChange}
                                />
                            }
                            label="1. Oświadczam, iż nie istnieją przeciwwskazania medyczne do udziału w zawodach."
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    value={values.rulesCredential}
                                    checked={values.rulesCredential}
                                    onChange={handleChange}
                                    name="rulesCredential"
                                />
                            }
                            label="2. Oświadczam, że zapoznałem się i akceptuję regulamin zawodów"
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    value={values.approvedUseDataCredential}
                                    onChange={handleChange}
                                    checked={values.approvedUseDataCredential}
                                    name="approvedUseDataCredential"
                                />
                            }
                            label="3. Niniejszym wyrażam zgodę na wykorzystywanie danych osobowych zawartych w formularzu zgłoszeniowym
                            przez organizatora imprezy i podmioty współpracujące do celów organizacyjnych."
                        />
                    </FormGroup>
                </FormControl>
                <div className={classes.captchaWrapper}>

                    <ReCAPTCHA
                        sitekey={`${process.env.REACT_APP_CAPTCHA_KEY}`}
                        onChange={(token) => {
                            helpers.setValue(token ?? '');
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default memo(RequiredCredentials);
