import { Formik } from 'formik';
import React, { createContext, memo } from 'react';
import { useEnroll } from '../../hooks/useEnroll';
import { useRegister } from '../../hooks/useRegister';
import RegisterForm from './RegisterForm';
import { AssignmentScheme, SignupSchema } from './signupValidationSchema';
export const OtherPersonAssignContext = createContext(false);
const RegisterWrapper = ({ isAssignOtherPerson, eventId }: { isAssignOtherPerson: boolean; eventId?: string }) => {
    const { register } = useRegister();
    const { assignEvent } = useEnroll();
    return (
        <div>
            <Formik
                initialValues={{
                    email: '',
                    ...(!isAssignOtherPerson && { password: '' }),
                    ...(!isAssignOtherPerson && { confirmPassword: '' }),
                    firstName: '',
                    lastName: '',
                    telNumber: '',
                    city: '',
                    gender: '',
                    club: '',
                    shirtSize: '',
                    dateBrith: '',
                    rodoCredential: false,
                    medicalCredential: false,
                    rulesCredential: false,
                    approvedUseDataCredential: false,
                    captcha: '',
                }}
                validationSchema={isAssignOtherPerson ? AssignmentScheme : SignupSchema}
                onSubmit={values => {
                    isAssignOtherPerson
                        ? assignEvent({ values, eventId, isAssignOtherPerson })
                        : register(values, isAssignOtherPerson);
                }}
            >
                <OtherPersonAssignContext.Provider value={isAssignOtherPerson}>
                    <RegisterForm />
                </OtherPersonAssignContext.Provider>
            </Formik>
        </div>
    );
};
export default memo(RegisterWrapper);
