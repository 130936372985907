import { TOGGLE_DRAWER_MOBILE_MENU, IToogleDrawer } from './types.mobilemenu';
import { IDrawerMenu } from '../types';

export const ToogleMenu = ({ isOpen }: IDrawerMenu): IToogleDrawer => {
    return {
        type: TOGGLE_DRAWER_MOBILE_MENU,
        payload: {
            isOpen,
        },
    };
};
