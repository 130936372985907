import * as Yup from 'yup';
export const SignupSchema = Yup.object().shape({
    firstName: Yup.string().required('Pole wymagane'),
    lastName: Yup.string().required('Pole wymagane'),
    email: Yup.string()
        .email('Nieprawidłowy adres email')
        .required('Pole wymagane'),
    telNumber: Yup.string()
        .matches(/^([0-9]{3})([0-9]{3})([0-9]{3})$/, 'Niepoprawny numer')
        .required('Pole wymagane'),
    city: Yup.string().required('Pole wymagane'),
    password: Yup.string()
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/,
            'Hasło musi zawierać jedną dużą literę, jedną małą literę, oraz cyfrę',
        )
        .min(8, 'Hasło musi mieć minimum 8 znaków')
        .required('Pole wymagane'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Hasła się nie zgadzają.')
        .required('Pole wymagane'),
    shirtSize: Yup.string().required('Pole wymagane!'),
    gender: Yup.string().required('Pole wymagane!'),
    club: Yup.string().max(20, 'Nazwa klubu nie może być dłuższa niż 20 znaków'),
    rodoCredential: Yup.bool()
        .oneOf([true])
        .required(),
    medicalCredential: Yup.bool()
        .oneOf([true])
        .required(),
    rulesCredential: Yup.bool()
        .oneOf([true])
        .required(),
    approvedUseDataCredential: Yup.bool()
        .oneOf([true])
        .required(),
    dateBrith: Yup.string()
        .matches(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/, 'Błędna data urodzin [rrrr-mm-dd]')
        .required('Pole wymagane Format: [rrrr-mm-dd]'),
    captcha: Yup.string().required(),
});

export const AssignmentScheme = Yup.object().shape({
    firstName: Yup.string().required('Pole wymagane'),
    lastName: Yup.string().required('Pole wymagane'),
    email: Yup.string()
        .email('Nieprawidłowy adres email')
        .required('Pole wymagane'),
    telNumber: Yup.string()
        .matches(/^([0-9]{3})([0-9]{3})([0-9]{3})$/, 'Niepoprawny numer')
        .required('Pole wymagane'),
    city: Yup.string().required('Pole wymagane'),
    shirtSize: Yup.string().required('Pole wymagane!'),
    gender: Yup.string().required('Pole wymagane!'),
    club: Yup.string().max(20, 'Nazwa klubu nie może być dłuższa niż 20 znaków'),
    rodoCredential: Yup.bool()
        .oneOf([true])
        .required(),
    medicalCredential: Yup.bool()
        .oneOf([true])
        .required(),
    rulesCredential: Yup.bool()
        .oneOf([true])
        .required(),
    approvedUseDataCredential: Yup.bool()
        .oneOf([true])
        .required(),
    dateBrith: Yup.string()
        .matches(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/, 'Błędna data urodzin [rrrr-mm-dd]')
        .required('Pole wymagane Format: [rrrr-mm-dd]'),
    captcha: Yup.string().required(),
});
