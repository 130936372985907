import { makeStyles } from '@material-ui/styles';

export const useStyle = makeStyles(() => ({
    root: {
        height: '90vh',
        width: '100vw',
        overflowX: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
}));
