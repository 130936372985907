export const MAIN_FORM_TOP_ERROR = 'MAIN_FORM_TOP_ERROR';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export interface IMainFormErrorAction {
    type: typeof MAIN_FORM_TOP_ERROR;
    payload: {
        formTopError?: string;
    };
}
export interface IMainFormErrorActionClear {
    type: typeof CLEAR_ERRORS;
}
