import produce from 'immer';
import { MAIN_FORM_TOP_ERROR, CLEAR_ERRORS } from './types.error';

export interface IErrorInitState {
    errors: {
        readonly formTopError: string;
    };
}

interface IReducer extends IErrorInitState {
    type: string;
    payload: PayloadType;
}

const authInitState: IErrorInitState = {
    errors: {
        formTopError: '',
    },
};
type PayloadType = Pick<IErrorInitState['errors'], 'formTopError'>;
export const errorsReducer = <T extends IReducer>(
    state: IErrorInitState = authInitState,
    action: T,
): IErrorInitState => {
    switch (action.type) {
        case MAIN_FORM_TOP_ERROR:
            const { formTopError } = action.payload;
            return produce<IErrorInitState>(state, draftState => {
                draftState.errors.formTopError = formTopError;
            });
        case CLEAR_ERRORS:
            return produce<IErrorInitState>(state, draftState => {
                draftState.errors.formTopError = '';
            });
        default:
            break;
    }
    return state;
};
export default errorsReducer;
