import { createTheme } from '@material-ui/core/styles';

export interface IThemeInterface {
	themeType: 'light' | 'dark';
	subforum: {
		primary: string;
		secondary: string;
		tertiary: string;
	};
	fontColor: string;
	iconColor: string;
	background: {
		primary: string;
		secondary: string;
		searchBox: string;
	};
	button: {
		primary: string;
		secondary: string;
	};
	borderColor: string;
	defaultFont: string;
	lightTheme: boolean;
}

export const lightTheme = createTheme({
           palette: {
               primary: {
                   // light: will be calculated from palette.primary.main,
                   main: '#008000',
                   // dark: will be calculated from palette.primary.main,
                   // contrastText: will be calculated to contrast with palette.primary.main
               },
               secondary: {
                   main: '#ffebee',
                   // dark: will be calculated from palette.secondary.main,
                   contrastText: '#000',
               },
               // error: will use the default color
           },
       });
