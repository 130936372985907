import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { displayInfo } from '../API/formNotification';
import { makeRequest } from '../API/reqAPI';
import { getAssignEventList } from '../redux/Enrolments/actions.enrolments';
import { IAppState } from '../redux/types';
import { IRegisterFormValues } from './useRegister';


export const useEnroll = () => {
    const dispatch = useDispatch();
    const emailStore = useSelector((state: IAppState) => state.auth.login.email);
    const club = useSelector((state: IAppState) => state.auth.login.club);
    return {
        assignEvent: async ({
            values,
            eventId,
            isAssignOtherPerson,
        }: {
            values?: IRegisterFormValues;
            isAssignOtherPerson: boolean;
            eventId?: string;
        }) => {
            const { isConfirmed } = await Swal.fire({
                title: `Czy na pewno chcesz zapisać ${isAssignOtherPerson ? 'kogoś' : "się"} na wydarzenie?`,
                html: '<b style="color: #d33;">WYPISANIE NIE BĘDZIE MOŻLIWE</b>',
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#008000',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Tak, zapisz!',
                cancelButtonText: 'Nie, nie zapisuj!',
            });

            if (isConfirmed) {
                const assignedParams = {
                    assignOtherPerson: isAssignOtherPerson,
                    ...(!isAssignOtherPerson && { email: emailStore }),
                    firstName: values?.firstName,
                    lastName: values?.lastName,
                    email: values?.email,
                    dateBrith: values?.dateBrith,
                    city: values?.city,
                    telNumber: values?.telNumber,
                    club: isAssignOtherPerson ? (values?.club ? values?.club : 'null') : club ? club : 'null',
                    shirtSize: values?.shirtSize,
                    gender: values?.gender,
                    eventId,
                };
                const res = await makeRequest('assigntoEvent', 'post', assignedParams);
                const isError = res?.error ? true : false;
                if (!isError) {
                    res.data
                        ? displayInfo(`Pomyślnie zapisano: ${values?.firstName} ${values?.lastName}`, 5000, dispatch)
                        : displayInfo('Wystąpił nieoczekiwany błąd', 5000, dispatch);
                } else {
                    displayInfo(res?.error, 5000, dispatch);
                }
                dispatch(getAssignEventList());
            }
        },
    };
};
