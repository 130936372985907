import { Dispatch } from 'redux';
import { displayInfo } from '../../API/formNotification';
import { makeRequest } from '../../API/reqAPI';
import { clearErrors } from '../Errors/action.errors';
import { ILogin } from '../types';
import { ILoginUserAction, ILogoutUserAction, IResponseLogin, LOGIN_USER, LOGOUT_USER } from './types.auth';

export const loginAction = ({ email, userId, isAdmin, club }: ILogin): ILoginUserAction => {
    return {
        type: LOGIN_USER,
        payload: {
            email,
            userId,
            isAdmin,
            club,
        },
    } as const;
};

export const logoutAction = (): ILogoutUserAction => {
    return {
        type: LOGOUT_USER,
    } as const;
};

export const LogoutUser = () => {
    return async (dispatch: Dispatch): Promise<void> => {
        await makeRequest<ILogin>('logout', 'post');
        dispatch(logoutAction());
    };
};

export const LoginUser = ({ email, password }: ILogin, history: any) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const req = await makeRequest<ILogin, IResponseLogin>('login', 'post', { email, password });
        try {
            const { userId, club, role } = req.data?.result;
            const isError = req?.error ? true : false;
            const isAdmin = role === 'ADMIN' ? true : false;
            if (!isError) {
                setTimeout(() => {
                    history.push('/apply');
                }, 400);
                dispatch(loginAction({ email, userId, isAdmin, club }));
                dispatch(clearErrors());
            }
        } catch (error) {
            displayInfo(req?.error, 5000, dispatch);
        }

    };
};
