import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import authReducer from './Auth/reducers.auth';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { mobileMenuReducer } from './MobileMenu/reducers.mobilemenu';
import { errorsReducer } from './Errors/reducers.errors';
import { enrolmentsReducer } from './Enrolments/reducers.enrolments';
const rootReducer = combineReducers({
    auth: authReducer,
    MobileMenu: mobileMenuReducer,
    errors: errorsReducer,
    Enrolments: enrolmentsReducer,
});

const saveToLocalStorage = (state: any) => {
    try {
        const serializeState = JSON.stringify(state);
        localStorage.setItem('state', serializeState);
    } catch (error) {
        console.log(error);
    }
};

const loadFromLocalStorage = () => {
    try {
        const serializedState = String(localStorage.getItem('state'));
        if (serializedState === null) return undefined;
        if (serializedState !== 'null') return JSON.parse(serializedState);
    } catch (error) {
        console.log(error);
        return undefined;
    }
};
const presistedStore = loadFromLocalStorage();

export const initializeStore = createStore(
    rootReducer,
    presistedStore,
    composeWithDevTools(applyMiddleware(thunkMiddleware)),
);

initializeStore.subscribe(() => {
    saveToLocalStorage(initializeStore.getState());
});
