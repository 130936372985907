/* eslint-disable react/prop-types */
import React, { memo, useContext } from 'react';

import { Button, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { IButtonsProps, IRegisterFormContext } from '../types';
import { OtherPersonAssignContext } from '../RegisterWrapper';

const Buttons = ({ data }: { data: IButtonsProps }) => {
    const { errors }: IRegisterFormContext = useFormikContext();
    const { actualStep, maxSteps, handleStepBackward, handleStepForward, classes } = data;
    const isAssignOtherPerson = useContext(OtherPersonAssignContext);
    const isRegisterEnable = Object.keys(errors).length <= 0 ? false : true;
    return (
        <>
            <Typography variant="h5" align="center" component="h2">
                Krok {actualStep} z {maxSteps}
            </Typography>
            <div className={classes?.buttonWrapper}>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={isRegisterEnable}
                    className={classes?.buttonItem}
                    type="submit"
                >
                    {isAssignOtherPerson ? 'Zapisz' : 'Zarejestruj się'}
                </Button>
                {actualStep > 1 && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleStepBackward}
                        className={classes?.buttonItem}
                    >
                        Wstecz
                    </Button>
                )}
                {actualStep < maxSteps && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleStepForward}
                        className={classes?.buttonItem}
                    >
                        Dalej
                    </Button>
                )}
            </div>
        </>
    );
};
export default memo(Buttons);
