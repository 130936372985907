import React from 'react';
import Navbar from '../components/Navbar/TopNav/Nav';
import SideMenu from '../components/Navbar/SideMenu/SideMenu';
import RegisterWrapper from '../components/RegisterForm/RegisterWrapper';

const Register = () => {
    return (
        <div>
            <Navbar />
            <SideMenu />
            <RegisterWrapper isAssignOtherPerson={false} />
        </div>
    );
};
export default Register;
