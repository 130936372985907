export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

export interface ILoginUserAction {
    type: typeof LOGIN_USER;
    payload: {
        email?: string;
        password?: string;
        isAdmin?: boolean;
        userId?: string;
        club?: string;
    };
}

export interface IResponseLogin {
    userId: string,
    role: string,
    club: string,
}

export interface ILogoutUserAction {
    type: typeof LOGOUT_USER;
}
