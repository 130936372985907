import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { LogoutUser } from '../../redux/Auth/action.auth';
import { IAppState } from '../../redux/types';

export const MenuItems = [
           { text: 'Zaloguj się', link: '/', isLogin: false, isAdmin: false, alwaysVisible: false },
           { text: 'Zarejestruj się', link: '/register', isLogin: false, isAdmin: false, alwaysVisible: false },
           { text: 'Wydarzenia', link: '/apply', isLogin: true, isAdmin: false, alwaysVisible: false },
           { text: 'Panel Admina', link: '/admin', isLogin: true, isAdmin: true, alwaysVisible: false },
           { text: 'Profil', link: '/profile', isLogin: true, isAdmin: false, alwaysVisible: false },
           {
               text: 'Wyloguj się',
               link: '#',
               isLogin: true,
               isAdmin: false,
               alwaysVisible: false,
               action: {
                   logout: (dispatch: ThunkDispatch<IAppState, void, Action>) => {
                       dispatch(LogoutUser());
                   },
               },
           },
       ];
