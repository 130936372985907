/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { displayInfo } from '../API/formNotification';
import { makeRequest } from '../API/reqAPI';

export interface IRegisterFormValues {
    email: string;
    password?: string;
    confirmPassword?: string;
    firstName: string;
    lastName: string;
    telNumber: string;
    city: string;
    club: string;
    gender: string;
    shirtSize: string;
    dateBrith: string;
    rodoCredential: boolean;
    medicalCredential: boolean;
    rulesCredential: boolean;
    approvedUseDataCredential: boolean;
}

export const useRegister = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    return {
        register: async (values: IRegisterFormValues, isAssignOtherPerson: boolean) => {
            const { rodoCredential, medicalCredential, rulesCredential, approvedUseDataCredential, ...rest } = values;
            const clubTypedValue = { club: rest.club ? rest.club : 'null' };
            const data = { ...rest, ...clubTypedValue };
            const res = await makeRequest('register', 'post', data);
            const isError = res?.error ? true : false;
            try {
                if (!isError) {
                    if (res.data) {
                        displayInfo('Konto założone, możesz się zalogować', 7000, dispatch);
                        localStorage.removeItem('signupForm');
                        setTimeout(() => {
                            history.push('/');
                        }, 2500);
                    } else {
                        displayInfo('Wystąpiły błędy w formularzu', 5000, dispatch);
                    }
                } else {
                    displayInfo(res?.error, 5000, dispatch);
                }
            } catch (e) {
                console.log(`[ERROR] ${e}`);
            }
        },
    };
};
