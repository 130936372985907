import { IErrors } from '../types';
import { MAIN_FORM_TOP_ERROR, IMainFormErrorAction, CLEAR_ERRORS, IMainFormErrorActionClear } from './types.error';

export const setFormTopError = ({ formTopError }: IErrors): IMainFormErrorAction => {
    return {
        type: MAIN_FORM_TOP_ERROR,
        payload: {
            formTopError,
        },
    } as const;
};
export const clearErrors = (): IMainFormErrorActionClear => {
    return {
        type: CLEAR_ERRORS,
    } as const;
};
