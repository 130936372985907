import { makeStyles } from '@material-ui/styles';

export const useStyle = makeStyles(() => ({
    root: {
        height: '90vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        '@media screen and (min-width: 414px)': {
            width: '50vw',
        },
        '@media screen and (min-width: 768px)': {
            width: '30vw',
        },
        '@media screen and (min-width: 1200px)': {
            width: '20vw',
        },
    },
    formItems: {
        marginTop: '10px',
    },
    errorLabel: {
        color: '#ed6258',
    },
}));
