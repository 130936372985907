import axios from 'axios';
import qs from 'qs';
export type Method = 'post' | 'get';
interface IResponse<T, K> {
    error: string;
    status: number;
    data: {
        result: T | K
    };
}
export const makeRequest = async <T, K = {}>(endpoint: string, method: Method, data?: T | K, additionalsHeader?: {}) => {
    const res: IResponse<T, K> = await axios({
        url: `${process.env.REACT_APP_API_ADDRESS}/${endpoint}`,
        method,
        headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
            ...additionalsHeader,
        },
        data: qs.stringify(data),
        withCredentials: true,
    }).catch(err => {
        return err.response?.data;
    });
    return res;
};
