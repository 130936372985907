import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { useStyle } from './Nav.style';
import { useDispatch } from 'react-redux';
import { IPropsNavItems } from '../types.navbar';

const TopNavItem = ({ props, isLogin }: { props: IPropsNavItems; isLogin: boolean }) => {
    const dispatch = useDispatch();
    const classes = useStyle();
    return (
        <Link to={props.link} key={props.text} className={classes.linkNavItem}>
            <Button
                className={classes.menuItem}
                key={props.text}
                onClick={isLogin ? () => props.action?.logout(dispatch) : () => null}
            >
                {props.text}
            </Button>
        </Link>
    );
};
export default TopNavItem;
