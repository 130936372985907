import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { LoginUser } from '../redux/Auth/action.auth';
interface ILogin {
    email: string;
    password: string;
}
interface IUseLogin {
    login: ({ email, password }: ILogin) => void;
}

export const useLogin = (): IUseLogin => {
    const dispatch = useDispatch();
    const history = useHistory();
    return {
        login: ({ email, password }: ILogin): void => {
            dispatch(LoginUser({ email, password }, history));
        },
    };
};
