import { AppBar, List, ListItem, Toolbar, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToogleMenu } from '../../../redux/MobileMenu/action.mobilemenu';
import { IAppState } from '../../../redux/types';
import { MenuItems } from '../navitems';
import { useStyle } from './Nav.style';
import TopNavItem from './TopNavItem';
const Navbar = () => {
    const classes = useStyle();
    const { isLogin, isAdmin } = useSelector((state: IAppState) => state.auth.login);
    const dispatch = useDispatch();
    const isMenuOpen = useSelector((state: IAppState) => state.MobileMenu.drawer.isOpen);

    return (
        <AppBar color="primary" className={classes.root} position="static">
            <Toolbar>
                <Typography variant="h6" className={classes.title} color="inherit">
                    Bieg Muzealny - system zapisów
                </Typography>
                <List component="nav">
                    <ListItem component="div">
                        <Button
                            className={classes.hamburger}
                            onClick={() => dispatch(ToogleMenu({ isOpen: !isMenuOpen }))}
                        >
                            &#9776;
                        </Button>
                        <div className={classes.standardMenu}>
                            {MenuItems.map(p => {
                                if (isAdmin) {
                                    if (p.isAdmin || p.isLogin || p.alwaysVisible)
                                        return <TopNavItem props={p} key={p.text} isLogin={p.isLogin} />;
                                } else if (isLogin) {
                                    if ((!p.isAdmin && p.isLogin) || p.alwaysVisible)
                                        return <TopNavItem props={p} key={p.text} isLogin={p.isLogin} />;
                                } else if (!isLogin) {
                                    if (!p.isLogin || p.alwaysVisible)
                                        return <TopNavItem key={p.text} props={p} isLogin={p.isLogin} />;
                                }
                                return false;
                            })}
                        </div>
                    </ListItem>
                </List>
            </Toolbar>
        </AppBar>
    );
};
export default Navbar;
