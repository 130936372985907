import { Dispatch } from 'redux';
import { IAssignEventList } from '../types';
import { SET_ASSIGN_EVENT_LIST, IAssignEventListPayload } from './types.enrolments';
import { makeRequest } from '../../API/reqAPI';
import { EventData } from '../../components/Enrolements/types';

export const setAssignEventList = ({ alreadyEnrolments, eventsData }: IAssignEventList): IAssignEventListPayload => {
    return {
        type: SET_ASSIGN_EVENT_LIST,
        payload: {
            alreadyEnrolments,
            eventsData,
        },
    } as const;
};
interface IDataAssign {
    alreadyEnrolments?: string[];
    eventsData: EventData;
}
export const getAssignEventList = () => {
    return async (dispatch: Dispatch): Promise<void> => {
        const res = await makeRequest<IAssignEventList, IDataAssign>('getevents', 'post');
        try {
            dispatch(
                setAssignEventList({ eventsData: res.data.result.eventsData, alreadyEnrolments: res.data.result.alreadyEnrolments }),
            );
        } catch (error) {
            console.error('Unexpected error');
        }

    };
};
