import { Button, List } from '@material-ui/core';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToogleMenu } from '../../../redux/MobileMenu/action.mobilemenu';
import { IAppState } from '../../../redux/types';
import { MenuItems } from '../navitems';
import { useStyle } from './SideMenu.style';
import SideMenuItem from './SideMenuItem';
const SideMenu = () => {
    const classes = useStyle();
    const dispatch = useDispatch();
    const { isLogin, isAdmin } = useSelector((state: IAppState) => state.auth.login);
    const isMenuOpen = useSelector((state: IAppState) => state.MobileMenu.drawer.isOpen);

    return (
        <>
            <AnimatePresence>
                {!!isMenuOpen && (
                    <motion.div
                        initial={{ top: '-100vh', display: 'none' }}
                        animate={{ top: '0', display: 'block' }}
                        exit={{ top: '-100vh', display: 'none' }}
                        className={classes.root}
                    >
                        <Button
                            variant="contained"
                            className={classes.closeBtn}
                            onClick={() => dispatch(ToogleMenu({ isOpen: !isMenuOpen }))}
                        >
                            ZAMKNIJ
                        </Button>

                        <List component="nav" disablePadding>
                            {MenuItems.map(p => {
                                if (isAdmin) {
                                    if (p.isAdmin || p.isLogin || p.alwaysVisible)
                                        return <SideMenuItem props={p} isLogin={p.isLogin} />;
                                } else if (isLogin) {
                                    if ((!p.isAdmin && p.isLogin) || p.alwaysVisible)
                                        return <SideMenuItem props={p} isLogin={p.isLogin} />;
                                } else if (!isLogin) {
                                    if (!p.isLogin || p.alwaysVisible)
                                        return <SideMenuItem props={p} isLogin={p.isLogin} />;
                                }
                                return false;
                            })}
                        </List>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};

export default SideMenu;
