import React, { memo, useContext } from 'react';
import { TextField } from '@material-ui/core';
import { useStyle } from '../RegisterForm.style';
import { useFormikContext } from 'formik';
import { IRegisterFormContext } from '../types';
import { OtherPersonAssignContext } from '../RegisterWrapper';

const AuthData = () => {
    const isAssignOtherPerson = useContext(OtherPersonAssignContext);
    const classes = useStyle();
    const { errors, values, handleChange, handleBlur }: IRegisterFormContext = useFormikContext();
    return (
        <div className={classes.formWrapper}>
            <TextField
                error={!!errors?.email ? true : false}
                className={classes.formItems}
                onChange={handleChange}
                onBlur={handleBlur}
                name="email"
                label="Email"
                value={values.email}
                helperText={errors?.email}
                variant="filled"
            />
            {!isAssignOtherPerson && (
                <>
                    <TextField
                        error={!!errors?.password ? true : false}
                        className={classes.formItems}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="password"
                        label="Hasło"
                        type="password"
                        value={values.password}
                        helperText={errors?.password}
                        variant="filled"
                    />
                    <TextField
                        error={!!errors?.confirmPassword ? true : false}
                        className={classes.formItems}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="confirmPassword"
                        label="Powtórz Hasło"
                        type="password"
                        value={values.confirmPassword}
                        helperText={errors?.confirmPassword}
                        variant="filled"
                    />
                </>
            )}
        </div>
    );
};

export default memo(AuthData);
