import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import LoginForm from '../components/LoginForm/LoginForm';
import SideMenu from '../components/Navbar/SideMenu/SideMenu';
import Navbar from '../components/Navbar/TopNav/Nav';
import { IAppState } from '../redux/types';

const Login = (): React.ReactElement => {
    const isAuth = useSelector((state: IAppState) => state.auth.login.isLogin);

    return (
        <div>
            <>
                {isAuth ? (
                    <>
                        <Redirect to="/apply" />
                    </>
                ) : (
                    <>
                        <Navbar />
                        <SideMenu />
                        <LoginForm />
                    </>
                    
                )}
                
            </>
        </div>
    );
};
export default Login;
