import { Typography } from '@material-ui/core';
import React from 'react';
import { useStyle } from './HomeTitle.style';

interface HomeTitle {
    title: string; 
    subtitle: string;
}

const HomeTitle = ({ title, subtitle }: HomeTitle) => {
    const classes = useStyle();

    return (
        <div className={classes.root}>
            <Typography variant="h2" align="center">
                {title}
            </Typography>
            <Typography variant="h5" align="center">
                {subtitle}
            </Typography>
            <Typography variant="body1" align="center">
                Autor Systemu: Jakub Wołodko
            </Typography>
        </div>
    );
};
export default HomeTitle;
