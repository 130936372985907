import { PulseLoader } from 'react-spinners';
import React from 'react';
import { useStyle } from './Loading.style';

const Loading = () => {
    const classes = useStyle();
    return (
        <div className={classes.root}>
            <PulseLoader size={25} margin={10} color={`#008000`} loading={true} />
        </div>
    );
};
export default Loading;
