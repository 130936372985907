/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React from 'react';
import { Link } from 'react-router-dom';
import { ListItem, ListItemText } from '@material-ui/core';
import { useStyle } from './SideMenu.style';
import { ToogleMenu } from '../../../redux/MobileMenu/action.mobilemenu';
import { useDispatch } from 'react-redux';
import { IPropsNavItems } from '../types.navbar';

const SideMenuItem = ({ props, isLogin }: { props: IPropsNavItems; isLogin: boolean }) => {
    const dispatch = useDispatch();
    const classes = useStyle();
    return (
        <Link to={props.link} key={props.text} className={classes.sideNavItem}>
            <ListItem
                button
                key={props.text}
                onClick={() => {
                    {
                        isLogin && props.action?.logout(dispatch);
                    }
                    dispatch(ToogleMenu({ isOpen: false }));
                }}
            >
                <ListItemText primary={props.text} />
            </ListItem>
        </Link>
    );
};
export default SideMenuItem;
