import produce from 'immer';
import { TOGGLE_DRAWER_MOBILE_MENU } from './types.mobilemenu';

export interface IMobileMenuInitState {
    drawer: {
        readonly isOpen: boolean;
    };
}
type PayloadType = Pick<IMobileMenuInitState['drawer'], 'isOpen'>;

interface IReducer extends IMobileMenuInitState {
    type: string;
    payload: PayloadType;
}

const authInitState: IMobileMenuInitState = {
    drawer: {
        isOpen: false,
    },
};

export const mobileMenuReducer = <T extends IReducer>(
    state: IMobileMenuInitState = authInitState,
    action: T,
): IMobileMenuInitState => {
    switch (action.type) {
        case TOGGLE_DRAWER_MOBILE_MENU:
            const { isOpen } = action.payload;
            return produce<IMobileMenuInitState>(state, draftState => {
                draftState.drawer.isOpen = isOpen;
            });
        default:
            break;
    }
    return state;
};
