import { makeStyles } from '@material-ui/styles';

export const useStyle = makeStyles(() => ({
    root: {
        height: '100vh',
        width: '100vw',
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: '#fff',
        zIndex: 1000,
    },
    closeBtn: {
        top: '0',
        left: '0',
        width: '100%',
    },
    sideNavItem: {
        textDecoration: 'none',
        color: '#000',
    },
}));
