import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';

import { Button, TextField, Typography, Link } from '@material-ui/core';
import { IAppState } from '../../redux/types';
import { useStyle } from './LoginForm.style';
import { useLogin } from '../../hooks/useLogin';
import { SigninSchema } from './signinValidationSchema';
import { clearErrors } from '../../redux/Errors/action.errors';
import { useHistory } from 'react-router';

const LoginForm = () => {
    const { login } = useLogin();
    const classes = useStyle();
    const dispatch = useDispatch();
    const error = useSelector((err: IAppState) => err.errors.errors.formTopError);
    useEffect(() => {
        dispatch(clearErrors());
    }, [dispatch]);
    const history = useHistory();
    return (
        <div className={classes.root}>
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                }}
                validationSchema={SigninSchema}
                onSubmit={({ email, password }) => {
                    login({ email, password });
                }}
            >
                {({ errors, handleChange, handleBlur, handleSubmit }) => (
                    <form
                        className={classes.form}
                        onSubmit={handleSubmit}
                        onKeyDown={e => {
                            if (e.key === 'Enter') handleSubmit();
                        }}
                    >
                        {!!error && (
                            <Typography className={classes.errorLabel} variant="body1" component="p">
                                {error}
                            </Typography>
                        )}
                        <TextField
                            error={!!errors.email ? true : false}
                            className={classes.formItems}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="email"
                            label="Email"
                            helperText={errors.email}
                            variant="filled"
                        />
                        <TextField
                            error={!!errors.password ? true : false}
                            className={classes.formItems}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="password"
                            label="Hasło"
                            helperText={errors.password}
                            variant="filled"
                            type="password"
                        />
                        <Link
                            component="a"
                            variant="body2"
                            href="#"
                            onClick={(e: React.MouseEvent<HTMLElement>) => {
                                e.preventDefault();
                                history.push('/resetPass');
                            }}
                        >
                            Jeśli zapomniałeś/aś hasła, Zresetuj je.
                        </Link>
                        <Button type="submit" variant="contained" className={classes.formItems} color="primary">
                            Zaloguj się
                        </Button>
                    </form>
                )}
            </Formik>
        </div>
    );
};
export default LoginForm;
