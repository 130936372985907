import { Typography } from '@material-ui/core';
import { Form, useFormikContext } from 'formik';
import React, { FormEvent, memo, useCallback, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from '../../redux/types';
import Buttons from './Buttons/Buttons';
import { useStyle } from './RegisterForm.style';
import { OtherPersonAssignContext } from './RegisterWrapper';
import AuthData from './Steps/AuthData';
import PersonalData from './Steps/PersonalData';
import PlayerData from './Steps/PlayerData';
import RequiredCredentials from './Steps/RequiredCredentials';
import { IRegisterFormContext } from './types';

const RegisterForm = () => {
    const classes = useStyle();
    const isAssignOtherPerson = useContext(OtherPersonAssignContext);
    const [actualStep, setActualStep] = useState(1);
    const maxSteps = 4;
    const { handleSubmit }: { handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void } = useFormikContext();
    const handleStepForward = useCallback(() => {
        setActualStep(prevStep => prevStep + 1);
    }, [setActualStep]);
    const handleStepBackward = useCallback(() => {
        setActualStep(prevStep => prevStep - 1);
    }, [setActualStep]);
    const error = useSelector((err: IAppState) => err.errors.errors.formTopError);
    const { errors }: IRegisterFormContext = useFormikContext();
    return (
        <div className={classes.root}>
            <Form className={classes.form} onSubmit={handleSubmit}>
                <Typography variant="h4" align="center" component="h2">
                    {isAssignOtherPerson ? 'Zapisz kogoś innego' : 'Zarejestruj się'}
                </Typography>
                {!!Object.keys(errors).length && (
                    <Typography className={classes.errorLabel} align="center" variant="body1" component="p">
                        Upewnij się, czy wszystkie wymagane pola zostały poprawnie uzupełnione i zaznaczone.
                    </Typography>
                )}
                {!!error && (
                    <Typography className={classes.errorLabel} align="center" variant="body1" component="p">
                        {error}
                    </Typography>
                )}
                <div>
                    {(() => {
                        switch (actualStep) {
                            case 1:
                                return <PersonalData />;
                            case 2:
                                return <AuthData />;
                            case 3:
                                return <PlayerData />;
                            case 4:
                                return <RequiredCredentials />;
                            default:
                                return null;
                        }
                    })()}
                </div>
                <Buttons data={{ actualStep, maxSteps, handleStepBackward, handleStepForward, classes }} />
            </Form>
        </div>
    );
};
export default memo(RegisterForm);
