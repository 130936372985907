import produce from 'immer';
import { SET_ASSIGN_EVENT_LIST } from './types.enrolments';
// import { LOGIN_USER, LOGOUT_USER } from './types.auth';

export interface IEnrolmentsInitState {
    enrolments: {
        alreadyEnrolments?: number[];
        eventsData: {
            id: number;
            eventName: string;
            rulesLink: string;
            joinedUser: number;
        };
    };
}

interface IReducer extends IEnrolmentsInitState {
    type: string;
    payload: PayloadType;
}

const authInitState: IEnrolmentsInitState = {
    enrolments: {
        eventsData: {
            id: 0,
            eventName: '',
            rulesLink: '',
            joinedUser: 0,
        },
        alreadyEnrolments: [],
    },
};
type PayloadType = Pick<IEnrolmentsInitState['enrolments'], 'alreadyEnrolments' | 'eventsData'>;
export const enrolmentsReducer = <T extends IReducer>(
    state: IEnrolmentsInitState = authInitState,
    action: T,
): IEnrolmentsInitState => {
    switch (action.type) {
        case SET_ASSIGN_EVENT_LIST:
            const { eventsData, alreadyEnrolments } = action.payload;
            return produce<IEnrolmentsInitState>(state, draftState => {
                draftState.enrolments.eventsData = eventsData;
                draftState.enrolments.alreadyEnrolments = alreadyEnrolments;
            });
        default:
            break;
    }
    return state;
};
export default enrolmentsReducer;
