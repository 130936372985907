/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { memo } from 'react';
import { useStyle } from '../RegisterForm.style';
import { IRegisterFormContext } from '../types';


const PlayerData = () => {
    const classes = useStyle();
    const { errors, values, handleChange, handleBlur }: IRegisterFormContext = useFormikContext();
    return (
        <div className={classes.formWrapper}>
            <TextField
                error={!!errors.club ? true : false}
                className={classes.formItems}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.club}
                name="club"
                label="Klub"
                helperText={errors.club}
                variant="filled"
            />

            <FormControl variant="filled" className={classes.formItems}>
                <InputLabel id="genderLabel">Wybierz płeć</InputLabel>
                <Select value={values.gender} name="gender" onBlur={handleBlur} onChange={handleChange}>
                    <MenuItem value="">Wybierz płeć</MenuItem>
                    <MenuItem value="M">Mężczyzna</MenuItem>
                    <MenuItem value="K">Kobieta</MenuItem>
                    <MenuItem value="Inne">Inne</MenuItem>
                </Select>
                {errors.gender ? <FormHelperText error>{errors.gender}</FormHelperText> : null}
            </FormControl>

            <FormControl variant="filled" className={classes.formItems}>
                <InputLabel id="shirtSizeLabel">Wybierz rozmiar koszulki</InputLabel>
                <Select value={values.shirtSize} name="shirtSize" onBlur={handleBlur} onChange={handleChange}>
                    <MenuItem value="">Wybierz rozmiar koszulki</MenuItem>
                    <MenuItem value="S">S</MenuItem>
                    <MenuItem value="M">M</MenuItem>
                    <MenuItem value="L">L</MenuItem>
                    <MenuItem value="XL">XL</MenuItem>
                    <MenuItem value="XXL">XXL</MenuItem>
                </Select>
                {errors.shirtSize ? <FormHelperText error>{errors.shirtSize}</FormHelperText> : null}
                
            </FormControl>

            <TextField
                error={!!errors.dateBrith ? true : false}
                className={classes.formItems}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.dateBrith}
                name="dateBrith"
                label="Data Urodzenia"
                helperText={errors.dateBrith}
                variant="filled"
            />
        </div>
    );
};

export default memo(PlayerData);
