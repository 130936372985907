import { ThemeProvider } from '@material-ui/core';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Background from './components/Background/Background';
import Loading from './components/Helpers/Loading';
import { initializeStore } from './redux/index';
import Routing from './Routing';
import { lightTheme } from './theme/theme';


const App = () => (
    <ThemeProvider theme={lightTheme}>
        <Provider store={initializeStore}>
            <Suspense fallback={<Loading />}>
                <BrowserRouter>
                    <Routing />
                </BrowserRouter>
            </Suspense>
        </Provider>
        <Background />
    </ThemeProvider>
);

export default App;
